import Swiper, {Navigation, SwiperOptions, Manipulation, Mousewheel, Pagination} from 'swiper';

const items = document.getElementsByClassName('hire-page');
const descriptions = document.getElementsByClassName('hire-page-description');
const header = document.getElementById('hire-header');
const close = document.getElementById('hire-close');
const center = document.getElementById('hire-center');
const prev = document.getElementById('hire-prev');
const next = document.getElementById('hire-next');
const wrapper = document.getElementById('swiper-wrapper')
const ACTIVE_CLASS_ITEM = 'hire-page_active';
const DISABLED_CLASS_ITEM = 'hire-page_disabled';
const ACTIVE_CLASS_HEADER = 'hire__left_active';
const ACTIVE_CLASS_DESCRIPTION = 'hire-page-description_active';
let firstBlockId = 0;

const nextClick = (swiper: Swiper) => { // срабатывает на переходе к след. картчке
    Array.from(descriptions).forEach((d: Element) => {
        if  (d.classList.contains('hire-page-description_active')) {
            hireCloser(null)
        }
    });
    Array.from(items).forEach((el, id) => {
        el.removeEventListener('click', onClick)
        el.removeEventListener('click', onClickSecond)
        el.removeEventListener('click', onClickThird)
        if (el.parentElement !== null && el.parentElement.classList.contains('swiper-slide-active')) {
            firstBlockId = id
        }
        if (firstBlockId + 1 === id) {
            el.addEventListener('click', onClick)
        } else if (firstBlockId + 2 === id) {
            el.addEventListener('click', onClickSecond)
        } else if (firstBlockId + 3 === id) {
            el.addEventListener('click', onClickThird)
        }
        if (![firstBlockId+1,firstBlockId+2,firstBlockId+3,firstBlockId+4].includes(id)) {
            el.classList.add('swap-hidden-active')
        } else {
            el.classList.remove('swap-hidden-active')
        }
        setTimeout(()=>{
            if (el.parentElement !== null && el.parentElement.classList.contains('swiper-slide-prev')) {
                el.classList.add('swap-hidden-active')
            }
            if (![firstBlockId+1,firstBlockId+2,firstBlockId+3,firstBlockId+4].includes(id)) {
                el.classList.add('swap-hidden-active')
            } else {
                el.classList.remove('swap-hidden-active')
            }
        }, 200)
        if (el.parentElement !== null && wrapper?.lastElementChild?.classList.contains('swiper-slide-next')) {
            prev?.click()
        }
    })
}
const onClickOpen = (hire_page: number) => {
    close.style.display = 'inline-block'
    close.style.zIndex = '10'
    close?.classList.add('mobile')
    if (!items || !descriptions) {
        return;
    }
    hireCloserListener();
    Array.from(descriptions).forEach((d: Element) => {
        d.classList.remove(ACTIVE_CLASS_DESCRIPTION);
    });

    items[firstBlockId+1].classList.add('active');
    descriptions[hire_page].classList.add('zIndex');
    descriptions[hire_page].classList.add(ACTIVE_CLASS_DESCRIPTION);

    let outerWidth = window.outerWidth
    if (outerWidth < 768) {
        descriptions[hire_page].addEventListener('click', e => {
            hireCloser(null)
        })
    }
};

const onClick = (evt: Event) => {
    const target = evt.currentTarget as HTMLElement;
    const hire_page = parseInt(target.dataset.hire || '0', 10);
    onClickOpen(hire_page)
}

const onClickSecond = (evt: Event) => {
    const target = evt.currentTarget as HTMLElement;
    const hire_page = parseInt(target.dataset.hire || '0', 10);
    next?.click()
    onClickOpen(hire_page)
};

const onClickThird = (evt: Event) => {
    const target = evt.currentTarget as HTMLElement;
    const hire_page = parseInt(target.dataset.hire || '0', 10);
    next?.click()
    setTimeout(()=> {
        next?.click()
        onClickOpen(hire_page)
    }, 700)
};

export const hireCloserListener = () => { // зайдний слой для закрытия карточки снаружи
    [close,header,center].forEach((el)=>{
        el.addEventListener('click', hireCloser);
        el.style.cursor = 'pointer'
    })
}

export const hireCloser = (evt: Event | null) => { // очистка стилей и закрытие описания

    setTimeout(() => {
        close?.classList.remove('mobile')
        close.style.zIndex = '0'
        close.style.display = 'none'
    },800)
    if (items[firstBlockId+1].classList.contains('active')) {
        items[firstBlockId + 1].classList.remove('active');
        items[firstBlockId + 1].classList.add(DISABLED_CLASS_ITEM);
        setTimeout(() => {
            items[firstBlockId + 1].classList.remove(DISABLED_CLASS_ITEM);
            Array.from(items).forEach((el) => {el.classList.remove(DISABLED_CLASS_ITEM)})
        }, 750)
        Array.from(descriptions).forEach((d: Element) => {
            d.classList.remove('zIndex')
            if  (d.classList.contains('hire-page-description_active')) {
                d.classList.add('removed');
                setTimeout(() => {
                    d.classList.remove(ACTIVE_CLASS_DESCRIPTION);
                }, 300)
                setTimeout(()=>{
                    d.classList.remove('removed')
                }, 300)
            }
        })
    }
    [close,header,center].forEach((el)=>{
        el?.removeEventListener('click', hireCloser);
        el.style.cursor = 'default'
    })
    Array.from(items).forEach((el) => {el.classList.remove('active')})
}

const SWIPER_PARAMS: SwiperOptions = {
    slidesPerView: 5,
    spaceBetween: 20,
    loop: true,
    speed: 500,
    navigation: {
        prevEl: '#hire-prev',
        nextEl: '#hire-next'
    },
    on: {
        slideNextTransitionStart: nextClick,
        slidePrevTransitionStart: nextClick,
        init: nextClick
    }
};

export const hire_slider = () => { // инициализацтя слайдера
    const length = items.length
    for (let i =0; i < length; i++) {
        let copy = wrapper?.children[i].outerHTML
        wrapper.innerHTML += copy
    }
    for (let i =0; i < length; i++) {
        let copy = wrapper?.children[i].outerHTML
        wrapper.innerHTML += copy
    }
    const el = document.getElementById('hire-page') as HTMLElement;
    Swiper.use([Navigation, Manipulation, Mousewheel, Pagination]);
    const swiper = new Swiper(el, SWIPER_PARAMS);
    setTimeout( () => {nextClick(swiper)}, 1200)

    
};
