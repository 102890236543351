const items = document.getElementsByClassName('nav__item');
const pages = document.getElementsByClassName('page');

const logos = document.getElementsByClassName('logo');

const ACTIVE_CLASS_ITEM = 'nav__item_active';
const ACTIVE_CLASS_PAGE = 'page_active';
const OUT_CLASS_ROUTER = 'router-out';

let inProgress = false;

const onClick = (evt: Event) => {
    if (inProgress) {
        return;
    }
    window.$menu.close();
    inProgress = true;
    document.body.classList.add(OUT_CLASS_ROUTER);
    window.setTimeout(() => {
        Array.from(items).forEach((i: Element) => {
            const b = i as HTMLElement;
            b.classList.remove(ACTIVE_CLASS_ITEM);
            document.body.classList.remove(b.dataset.id || 'index');
        });
        Array.from(pages).forEach((p: Element) => {
            p.classList.remove(ACTIVE_CLASS_PAGE);
        });
        const target = evt.target as HTMLElement;
        target.classList.add(ACTIVE_CLASS_ITEM);
        const {id} = target.dataset;
        const page = document.getElementById(id || 'index');
        page?.classList.add(ACTIVE_CLASS_PAGE);
        document.body.classList.add(id || 'index');
        window.setTimeout(() => {
            document.body.classList.remove(OUT_CLASS_ROUTER);
        }, 500);
        inProgress = false;
    }, 500);
};

export const router = () => {
    Array.from(items).forEach((i) => {
        i.addEventListener('click', onClick);
    });
    Array.from(logos).forEach((l) => {
        l.addEventListener('click', onClick);
    });
};
