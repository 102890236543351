const links = document.querySelectorAll('.tab__link span');
const items = document.getElementsByClassName('tab__item');
const currentIndex = document.getElementById('tab-index') as HTMLElement;

const ACTIVE_CLASS_LINK = 'active';
const ACTIVE_CLASS_ITEM = 'tab__item_active';

const onClick = (evt: Event) => {
    if (!links || !items || !currentIndex) {
        return;
    }
    Array.from(links).forEach((l: Element) => {
        l.classList.remove(ACTIVE_CLASS_LINK);
    });
    Array.from(items).forEach((i: Element) => {
        i.classList.remove(ACTIVE_CLASS_ITEM);
    });

    const target = evt.target as HTMLElement;
    const index = parseInt(target.dataset.index || '0', 10);

    links[index].classList.add(ACTIVE_CLASS_LINK);
    items[index].classList.add(ACTIVE_CLASS_ITEM);
    currentIndex.innerHTML = `${index + 1}`;
};

export const tab = () => {
    Array.from(links).forEach((i) => {
        i.addEventListener('click', onClick);
    });
};
