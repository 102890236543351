import './assets';
import 'swiper/css';
import {back} from './js/back';
import {NavComponent} from './js/nav';
import {router} from './js/router';
import {slider} from './js/slider';
import {hire_slider} from './js/hire-slider';
import {tab} from './js/tab';

document.addEventListener('DOMContentLoaded', () => {
    window.setTimeout(() => {
        document.body.classList.add('loaded');
    }, 500);
});

router();
tab();
slider();
hire_slider();
back();

window.$menu = new NavComponent();
window.$menu.run();

// @ts-ignore
const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
const className = isTouchDevice ? 'touch-device' : 'not-touch-device';
document.body.classList.add(className);

declare global {
    interface Window {
        $menu: NavComponent;
    }
}
